@charset "UTF-8";
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

h1 {
  font-family: Codec Bold;
  text-transform: uppercase;
}

h2 {
  font-family: Codec Bold;
  text-transform: uppercase;
}

h5 {
  font-family: Codec Bold;
  font-size: 18px !important;
}

h6 {
  font-family: Sora Regular;
}

span {
  font-family: Sora Regular;
}

.img-fluid {
  width: 100% !important;
  height: auto;
}

button {
  font-family: Sora Regular;
}

p {
  font-family: Sora Regular;
  font-size: 14px !important;
}

@font-face {
  font-family: Codec Bold;
  src: url(assets/font/codec.warm-trial-bold.ttf);
}
@font-face {
  font-family: Codec Regular;
  src: url(assets/font/codec-warm-trial-regular.ttf);
}
@font-face {
  font-family: Poppins Medium;
  src: url(assets/font/Poppins-Medium.ttf);
}
@font-face {
  font-family: Sora Regular;
  src: url(assets/font/Sora-Regular.ttf);
}
.mt-5 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 100px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

@media (min-width: 1200px) {
  .container {
    width: 1170px !important;
  }
}
/********** Nav Bar Start ************/
.banner-area-main {
  background-color: #EBF6E8;
  padding-bottom: 40px !important;
}

#banner-areag {
  padding: 1px 0 0;
  margin: 0;
}

.content {
  width: 94%;
  margin: 4em auto;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
}

/********** Banner area ************/
.main-content p {
  line-height: 27px;
  color: #000000B2;
}

.banner-area {
  position: relative;
  background-color: #ce2829;
  position: relative;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: 64%;
}

.contact-nav {
  border: solid 1px;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  padding: 7px;
  font-weight: 100;
  font-family: Agency-regular;
}

.bg-min {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.banner-bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: url(/src/assets/img/bg-img.png);
  background-size: 100% 90%;
  transform: scale(1.1);
}

.banner-area .banner-thumb {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0px);
  width: 100%;
  text-align: center;
}

.banner-inner {
  width: calc(100% - 20%);
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  height: 290px;
}

.banner-inner h1 {
  color: #fff;
  font-size: 60px;
  letter-spacing: 2px;
}

.banner-inner h3 {
  color: #fdc913;
  font-family: Blackadder ITC;
  font-size: 30px;
}

.banner-shape-1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.banner-shape-2 {
  position: absolute;
  right: 0;
  top: 0;
}

.banner-btn {
  background-color: #fdc913;
  border: none;
  border-radius: 50px;
  padding: 9px 55px;
  color: #ce2829;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: 1s;
  font-size: 18px;
  font-family: Agency-regular;
}

/********** About area ************/
.overlay-about {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: #7cc667d9;
  border-radius: 15px;
}

.overlay-about:hover .overlay {
  opacity: 1;
}

.overlay-text {
  color: white;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  text-align: left;
}

.overlay-text h5 {
  font-size: 30px;
}

.overlay-border {
  background-color: #fff;
  height: 2px;
  width: 200px;
}

.facilities-item img {
  border-radius: 10px;
}

/********** Menu Products area ************/
/********** Services area ************/
.services-area h2 {
  color: #20618E;
}

@media (max-width: 576px) {
  .services-item {
    height: 300px !important;
  }
}
.services-item {
  background-color: #fff;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  border: solid 1px #20618E;
  height: 340px;
  border-radius: 10px;
}

.services-item h5 {
  margin-top: 30px;
  font-size: 19px;
}

.services-item img {
  background-color: #dbe9f3;
  border-radius: 50px;
  padding: 10px;
}

.services-item h5 {
  color: #20618E;
}

.services-item p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
}

.service-overlay-area .service-overlay-item {
  width: 100%;
  transition: transform 0.3s ease;
}

.service-overlay-item:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease;
}

.service-overlay-item:hover {
  background-color: #7CC667;
}

.service-a {
  text-decoration: none;
}

/********** About area ************/
.sub-title h2 {
  color: #20618E;
}

.about-area {
  background-color: #EBF6E8;
  padding-top: 80px;
  padding-bottom: 80px;
}

.about-area img {
  border-radius: 10px;
}

.about-area h6 {
  font-size: 14px;
}

.service_tag {
  text-decoration: none;
}

.more-button {
  background: transparent;
  border: none;
  font-family: Sora Regular;
  color: #4D4D4D;
  margin-left: -5px;
}

.more-button-area img {
  padding-left: 10px;
}

.about-content-area {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 30px;
}

/********** Doctors area ************/
.doctors-content span {
  font-family: Sora Regular;
  font-size: 14px;
  color: #20608D;
  margin-bottom: 5px;
}

.doctors-content p {
  margin-top: 5px;
  font-size: 15px;
  color: #000000B2;
}

.doctors-content h5 {
  color: #20618E;
  margin-top: 20px;
  margin-bottom: 0;
}

.doctors-content img {
  border-radius: 10px;
  box-shadow: 0px 24px 32px -3px rgba(3, 9, 50, 0.04);
}

.doctors-overlay-area .doctors-overlay-item {
  width: 100%;
  transition: transform 0.3s ease;
}

.doctors-overlay-item:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease;
}

/********** Our Blog area ************/
.our-blog-area {
  background-color: #EBF6E8;
  padding-top: 90px;
  padding-bottom: 90px;
}

.blog-right-details {
  padding-left: 20px;
}

.blog-details img {
  border-radius: 10px;
  height: 230px;
}

.blog-details h5 {
  color: #20618E;
  margin-top: 20px;
  margin-bottom: 0;
}

.blog-details p {
  color: rgba(0, 0, 0, 0.7);
  line-height: 24px;
  font-size: 15px;
  margin-top: 10px;
}

.blog-right-details h5 {
  color: #20618E;
  font-size: 18px;
}

.blog-right-details span {
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px !important;
}

.blog-right-details h5 {
  margin-top: 15px !important;
  margin-bottom: 0;
}

.blog-right-img img {
  border-radius: 10px;
  width: 140px;
}

/********** Publications area ************/
.publications-details {
  background-color: #EBF6E8;
  padding: 30px;
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: left;
  border-radius: 10px;
}

.publications-details p {
  color: #000000B2;
  font-size: 15px;
}

.publications-details img {
  border-radius: 15px;
}

/********** Video section area ************/
.video-play-button {
  position: absolute;
  z-index: 10;
  /* top: 50%;
  left: 50%; */
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 36px 20px 14px 40px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #20618E;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 45px;
  height: 45px;
  background: #20618E;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: #5db644;
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 18px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}

.video-overlay-close:hover {
  color: #fa183d;
}

.video-overlay iframe {
  position: absolute;
  /* top: 50%;
  left: 50%; */
  transform: translateX(-50%) translateY(-50%);
  /* width: 90%; */
  /* height: auto; */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
}

.video-btn-area {
  bottom: 0;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  margin: auto;
  align-items: center;
}

.video-area {
  position: relative;
}

.video-area img {
  border-radius: 15px;
}

.video-text h5 {
  color: #20618E;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 17px;
}

.video-text p {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
}

/********** Enquiry area ************/
.enquiry-img {
  margin-top: -140px;
}

.enquiry-main {
  background: url(./assets/img/enquiry-bg.png);
  padding-top: 40px;
  padding-bottom: 0px;
  background-repeat: no-repeat;
  width: 100% !important;
  /* height: 530px; */
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}

.enquiry-item {
  position: relative;
}

.women-area {
  position: absolute;
  bottom: 21%;
}

.enquiry-content {
  position: absolute;
  top: 0;
}

.enquiry-item {
  padding-right: 60px;
  padding-left: 60px;
  padding-bottom: 60px;
}

@media (max-width: 576px) {
  .enquiry-item {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 50px;
  }
}
.enquiry-item h2 {
  color: #fff;
  margin-top: 30px;
}

.enquiry-item p {
  color: #fff;
  margin-top: 30px;
}

.enquiry-btn {
  background: #20618E;
  width: 100%;
  border: none;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  transition: 1s all;
}

.enquiry-btn:hover {
  background: transparent;
  border: solid 1px #20618E;
  color: #20618E;
}

.location-details p {
  padding-left: 10px;
  font-size: 15px;
}

.link-item li {
  padding-left: 30px;
  font-family: Sora Regular;
  padding-top: 20px;
  font-size: 13px;
}

.footer-links i {
  padding-left: 20px;
}

/********** Footer area ************/
.footer-bg {
  position: relative;
  background-color: #EBF6E8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.footer-list a {
  z-index: 999;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  letter-spacing: 1px;
}

.list-item {
  transition: 1s;
}

.list-item:hover {
  color: #c20608;
}

.footer-list li {
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 10px;
  font-family: Agency-regular;
  position: relative;
}

.footer-list span {
  padding-left: 10px;
}

.footer-list i {
  font-size: 10px;
}

.footer-main {
  padding-top: 80px;
  padding-bottom: 20px;
}

.footer-title h3 {
  color: #1C1D1D;
  font-size: 20px;
  padding-left: 10px;
  font-family: AnekLatin SemiBold;
  font-weight: 100;
  letter-spacing: 1px;
}

.listing-min {
  padding-right: 30px;
}

.footer-details p {
  color: #fff;
  font-family: Agency-regular;
  font-weight: 100;
}

.footer-content-item img {
  text-align: center;
}

.footer-content-item {
  margin-bottom: 20px;
}

.footer-logo {
  max-width: 200px;
  margin: auto;
}

.footer-contact {
  display: inline-flex;
}

.footer-contact img {
  width: 30px;
  height: 25px;
}

.mail-ic {
  padding-right: 7px;
}

.footer-about-item p {
  color: #1C1D1D;
  font-family: AnekLatin Regular;
  font-weight: 100;
  padding-left: 10px;
}

.footer-social-ic {
  color: #000000;
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 5px;
}

.footer-social-icons {
  padding-left: 7px;
}

.footer-social-icons i {
  margin-left: 4px;
}

.copyright-item {
  border-top: solid 1px #444;
  padding-top: 50px;
  margin-top: 20px;
}

.copyright-item p {
  color: #fff;
}

ul.breadcrumb {
  padding: 0;
  list-style: none;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 14px;
  color: #7CC667;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/ ";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #212529;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

/********** ABOUT PAGE START ************/
.diffrent-feature {
  background-color: #20618E;
  padding: 30px 0;
  border-radius: 10px;
  text-align: center;
  height: 100px;
  width: 100px;
  justify-content: space-around;
}

/********** SERVICE DETAILS PAGE Start ************/
.service-details-area .service-details-content img {
  border-radius: 10px;
}
.service-details-area .service-details-content h2 {
  margin-top: 30px;
}
.service-details-area .service-details-content .other-datas img {
  border-radius: 10px;
}
.service-details-area .details-right .details-right-img img {
  border-radius: 10px;
  width: 135px !important;
  height: 100px !important;
}
.service-details-area .details-right .service-right-details {
  padding-left: 20px;
  padding-right: 20px;
}
.service-details-area .details-right .service-right-details h5 {
  color: #20618E;
  font-size: 18px;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav li {
  display: inline-block;
}
nav a {
  display: block;
  text-decoration: none;
}

.blog-details h2 {
  color: #20618E;
  margin-top: 20px;
  text-transform: capitalize !important;
}
.blog-details span {
  margin-top: 20px;
  color: #000000B2;
  font-size: 14px;
}
.contact-area h2 {
  color: #20618E;
}

.form-field-area {
  margin-left: 0px;
  margin-right: 0px;
}

.contact-bg-light {
  background-color: #F7F7F7;
  padding-top: 60px;
  padding-bottom: 60px;
}

input {
  font-family: Sora Regular;
}

.form-min {
  width: 100%;
  border-radius: 0px;
  border: 1px solid rgba(24, 25, 69, 0.2);
  border-radius: 10px;
  outline: 0px !important;
  padding: 20px 20px;
  background: transparent;
  font-family: Sora Regular;
  color: rgba(24, 25, 69, 0.6);
}

.form-min label {
  text-transform: uppercase !important;
  font-family: Sora Regular;
}

.form-text-area {
  width: 100% !important;
  border-radius: 0px;
  border: 1px solid rgba(24, 25, 69, 0.2);
  border-radius: 10px;
  height: 320px;
  padding: 10px 20px;
  outline: 0px !important;
  font-family: Sora Regular;
  background: transparent;
}

input[type=text]:focus {
  border: 3px solid #000000 !important;
}

input[type=text] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  transition: 0.5s;
  outline: 0px !important;
}

.contact-text-area {
  position: relative;
}

.button-item {
  position: absolute;
  right: 0;
}

.contact-button {
  background-color: #20618E;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 2px;
  padding: 20px 35px;
  color: #fff;
  font-size: 15px;
  border-radius: 10px;
  text-transform: uppercase;
}
.contact-button:hover {
  background-color: #7CC667;
  color: white;
}

.form-item {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px #20618E;
  border-radius: 10px;
}

.select-box {
  border-radius: 10px;
  float: right;
  border: 1px solid rgba(24, 25, 69, 0.2);
  background: transparent;
  padding: 20px 20px;
  width: 100%;
  color: rgba(24, 25, 69, 0.6);
}

.mt-100 {
  margin-top: 120px !important;
}

/********** Media Start ************/
@media (max-width: 576px) {
  .overlay-text {
    font-size: 16px;
  }

  .container {
    padding: 0 30px;
  }

  .mt-100 {
    margin-top: 160px !important;
  }

  .about-content-area {
    padding-left: 0;
    padding-right: 0;
  }

  .d-none-mob {
    display: none !important;
  }

  .mt-50-mob {
    margin-top: 0px !important;
  }

  nav ul {
    text-align: left;
  }

  nav ul {
    padding-right: 40px;
  }

  nav ul li a {
    font-size: 20px;
    line-height: 0px;
  }

  .nav-btn {
    margin-left: 20px;
  }

  .logo {
    margin: 4px 12px;
  }

  .image-abt {
    width: 100%;
    height: 100%;
  }

  .enter-email {
    margin-left: 0px;
  }

  .copyright p {
    text-align: center;
  }

  .abt-img-two {
    margin-top: 0px;
  }

  .abt-img-four {
    margin-top: 0px;
  }

  .abt-img-five {
    margin-top: 0px;
  }

  .abt-img-six {
    margin-top: 0px;
  }

  .mob-padding {
    padding-right: 15px;
    padding-left: 15px;
  }

  .mob-margin {
    margin-left: 15px;
    margin-right: 15px;
  }

  .form-field {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .d-none-desk {
    display: none;
  }

  .enquiry-area {
    margin-top: 190px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .banner-area {
    height: 50vh !important;
  }

  header {
    height: 8vh !important;
  }

  nav ul {
    text-align: left;
  }

  .nav-btn {
    margin-left: 20px;
  }

  .top-bod {
    top: 11%;
  }

  .d-none-pd {
    display: none !important;
  }

  #banner-areag {
    padding: 1px 0 0;
  }
}
@media (min-width: 992px) {
  .center-align-text {
    justify-content: center !important;
    margin-top: 110px;
  }
}
/* ====== Journals ====== */
#Journals {
  display: none;
  background-color: var(--green-light);
}

#Journals-mobile {
  display: none;
  background-color: var(--green-light);
}

#Journals .main-Journals {
  margin-bottom: 2rem;
}

#Journals .main-Journals img {
  width: 100%;
  max-width: 840px;
  height: auto;
}

#Journals .main-Journals h1 {
  font-family: var(--Mulish);
  color: var(--black);
  font-size: 2rem;
  margin-top: 1.5rem;
}

#Journals .main-Journals span {
  color: #000000B2;
}

#Journals .main-Journals p {
  margin-top: 1rem;
  font-weight: 400;
  max-width: 50rem;
  text-align: left;
}

#Journals .blog-details p {
  max-width: 98%;
  text-align: left;
}

#Journals .main-Journals a {
  text-decoration: none;
  color: var(--green);
}

#Journals .main-Journals:hover > a {
  color: var(--green);
  transition: 0.2s ease-in-out;
}

.Journals-item h5 {
  padding-left: 10px;
  color: #20618E;
}

.Journals-item p {
  padding-left: 10px;
}

#Journals .more-Journals h2 {
  color: var(--black);
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
}

#Journals .more-Journals img {
  border-radius: 10px;
  width: 135px !important;
  height: 100px !important;
}

#Journals .more-Journals h3 {
  font-size: 1.5rem;
  color: var(--black);
  font-weight: 400;
  line-height: 1.2;
  margin-top: 1rem;
}

#Journals .more-Journals p {
  margin-top: 0.5rem;
  font-weight: 400;
  /* max-width: 21rem; */
}

#Journals .more-Journals a {
  text-decoration: none;
  color: var(--green);
  transition: 0.2s ease-in-out;
}

#Journals .more-Journals .Journals-item:hover > a {
  color: var(--green);
}

@media (max-width: 760px) {
  #Journals .more-Journals a {
    color: var(--green);
  }
}
/* @media (min-width:770px) {
  #Journals .more-Journals img{
    max-width: 20.5rem;
  }
  #Journals .more-Journals p{
    max-width: 21rem;
  }
} */
@media (min-width: 700px) {
  #Journals {
    display: block;
  }

  #Journals-mobile {
    display: none;
  }
}
@media (max-width: 700px) {
  #Journals-mobile {
    display: block;
  }
}
/* Journals-mobile */
#Journals-mobile .Journals-slider .item {
  background-color: var(--white);
  padding-bottom: 0.85rem;
}

#Journals-mobile .Journals-slider .item h3 {
  font-size: 1.5rem;
  color: var(--black);
  font-weight: 400;
  line-height: 1.2;
  margin-top: 1rem;
  padding-left: 0.85rem;
}

#Journals-mobile .Journals-slider .item span {
  padding-left: 0.85rem;
}

#Journals-mobile .Journals-slider .item p {
  margin-top: 0.5rem;
  padding-left: 0.85rem;
  font-weight: 400;
  padding-right: 1rem;
  /* max-width: 21rem; */
}

#Journals-mobile .Journals-slider .item a {
  text-decoration: none;
  color: var(--green);
  padding-left: 0.85rem;
}

.more-button {
  transition: 1s all;
}

.more-button:hover {
  margin-left: 15px;
  color: #7CC667;
}

.ngx-pagination .current {
  padding: 8px 20px !important;
  background: #20618E !important;
  color: #fefefe;
  cursor: default;
  border-radius: 10px;
}

a:not([href]):not([class]):hover {
  background: #20618E !important;
  padding: 8px 20px !important;
  color: #fff !important;
}

.ngx-pagination li {
  margin: 5px !important;
}

.pagination-area ul {
  padding-left: 0 !important;
}